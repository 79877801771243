import { BigFloat } from "shared/src/utils/BigFloat";
import { AssetId } from "shared/src/SupportedCurrencies";
import { filterPositiveNumbers } from "shared/src/utils/InputFilters";

const frontalSymbols = ["USD", "CHF", "GBP", "EUR", "USDC"];

export const isFrontalSymbol = (currency: AssetId) =>
  frontalSymbols.includes(currency);

export const currencySymbols: Record<Exclude<AssetId, "DAI">, string> = {
  NOK: "kr",
  SEK: "kr",
  DKK: "kr",
  USD: "$",
  USDC: "$",
  EUR: "€",
  CHF: "₣",
  GBP: "£",
  ETH: "ETH",
  CBBTC: "cbBTC",
};

/**
 * Reads the format type from a dictionary of AssetId's and checks whether the symbol goes in front or not.
 * Special formatting for DAI."
 * @param currency The AssetId to choose the format for.
 * @param value The value to affix the currency to.
 * @returns The formatted value.
 */
export function formatCurrency(currency: AssetId, value?: string) {
  if (currency === "DAI") {
    return "$" + value + " " + "DAI";
  }

  if (isFrontalSymbol(currency)) {
    return currencySymbols[currency] + value;
  }

  return value + " " + currencySymbols[currency];
}

/**
 * Adds proper currency signage to a number string and formats it to match the following requirements:
 * - 0 should always be without decimals
 * - 0 cannot start a number unless followed by a decimal point
 * - Decimal points at the end of strings must be preserved
 * - Extra decimal points must be removed
 * - The integer part must contain spaces every third digit from the back
 * - If formatDecimals: show specified decimal amount. If the amount is not specified, use default value
 * - If !formatDecimals: leave the decimal part as is
 * @param currency The AssetId to choose the format for.
 * @param value The value to affix the currency to & to be formatted. Can be null, undefined, number, string or BigFloat.
 * @param formatDecimals Whether or not to apply decimal formatting. Default set to true.
 * @param decimals Number of decimal spaces to show. Default value used if not specified. Ignored if formatDecimals is set to false.
 * @returns The formatted string value.
 */
export function formatToDisplayStringWithCurrency(
  currency: AssetId,
  value: string | number | BigFloat,
  formatDecimals?: boolean,
  decimals?: number
) {
  return formatCurrency(
    currency,
    formatToDisplayString(value, formatDecimals, decimals)
  );
}

/**Filters for numbers and replaces any "," in input with "." */
export function formatFromDisplayString(value: string) {
  return filterPositiveNumbers(value.replace(",", "."));
}

/**
 * Formats a number string to match the following requirements:
 * - 0 should always be without decimals
 * - 0 cannot start a number unless followed by a decimal point
 * - Decimal points at the end of strings must be preserved
 * - Extra decimal points must be removed
 * - The integer part must contain spaces every third digit from the back
 * - If formatDecimals: show specified decimal amount. If the amount is not specified, use default value
 * - If !formatDecimals: leave the decimal part as is
 * @param value Value to be formatted. Can be null, undefined, number, string or BigFloat
 * @param formatDecimals Whether or not to apply decimal formatting. Default set to true.
 * @param decimals Number of decimal spaces to show. Default value used if not specified. Ignored if formatDecimals is set to false.
 * @returns The formatted string value.
 */
export function formatToDisplayString(
  value?: string | number | BigFloat | null,
  formatDecimals: boolean = true,
  decimals: number = 2
) {
  // 1. specifically checks for 0 and return it, 0 should always be without decimals
  if (value === 0) return "0";

  // 2. returns empty string in cases of the value being null, undefined or an empty string
  if (!value) return "";

  // 3. makes sure that the value is a string
  if (typeof value !== "string") {
    if (typeof value === "number") {
      value = value.toString();
    } else {
      value = value.formatAndRound(value.decimals).toString();
    }
  }

  // 4. makes sure the string is a positive number, removes unwanted symbols
  value = filterPositiveNumbers(value.replace(",", "."));

  // 5. prevents user from writing 0 at the start of a whole number
  value = value.replace(/^0+(?=\d)/, "");

  // 6. Remove any extra decimal points except for the first one
  value = value.replace(/(\..*?)\.+/g, "$1");

  // 7. Splits integer and decimal parts
  let [integerPart, decimalPart] = value.split(".");

  // 8. Formats the integer part using regex to insert spaces every three digits
  integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, " ");

  // 9. Formats decimals if not set to false
  if (formatDecimals) {
    decimalPart =
      decimalPart !== undefined
        ? decimalPart.slice(0, decimals).padEnd(decimals, "0")
        : (decimalPart = "".padEnd(decimals, "0"));
  }

  // 10. Check that the initial value ends with . and append it if it does
  const endChar = value.endsWith(".") ? "." : "";

  return decimalPart ? `${integerPart}.${decimalPart}` : integerPart + endChar;
}
